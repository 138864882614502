'use strict';

var atrack;

/**
 * Initialize the GDDL analytics tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

const initSearchFilters = () => {
    $(document).on('search:filter', function (e, $this) {
        let selection = {};
        let updatedKey = '';
        let newValue = '';
        let toggledOn = false;
        let position = '';

        const $refinementsFlyout = $('.refinements.js-refinements-flyout');
        const expanded = $refinementsFlyout.length && $refinementsFlyout.hasClass('show');
        const reset = $this && $this.closest('.filter-bar__value--remove-all').length > 0;

        if ($this && !reset) {
            if ($this.closest('.filter-value').length > 0) {
                // Removing a value
                updatedKey = $this.closest('.filter-value').data('object').id;
                position = $this.closest('.filter-value').index('.filter-value') + 1;
            } else {
                // Adding or removing a filter
                updatedKey = $this.closest('[data-refinement]').data('refinement');
                position = $this.closest('[data-refinement]').index('[data-refinement]') + 1;
            }

            newValue = $this.find('span').text().trim();

            const selectedFilters = JSON.parse($('.filter-bar meta[itemprom="analytics"]').attr('content')); // Contains every active filter (after update)
            selectedFilters.forEach((filter) => {
                // Search for updated filter in the active filters
                if (filter.id === updatedKey && filter.displayValue === newValue) {
                    toggledOn = true; // since the current filter exists in the active filters, the update_method is 'add'
                }

                if (filter.id in selection) {
                    selection[filter.id] += '|' + filter.displayValue;
                } else {
                    selection[filter.id] = filter.displayValue;
                }
            });
        }

        const eventData = {
            event: reset ? 'filter-reset' : 'filter-update',
            info: {
                name: 'search-filter',
                type: expanded ? 'expanded' : 'compact',
                selection: selection,
                field_name: updatedKey,
                value: newValue,
                position: position,
                update_method: toggledOn ? 'add' : 'remove'
            }
        };

        atrack.trackEvent(eventData);
    });
};

const init = () => {
    initAnalyticsTracker();
    initSearchFilters();
};

$(() => {
    init();
});
